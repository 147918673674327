.markdown {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  color: $text-color;
  font-size: 13px;
  line-height: 1.6;
  word-wrap: break-word;

  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    &:active,
    &:hover {
      outline-width: 0;
    }
  }
  strong {
    font-weight: inherit;
    font-weight: bolder;
  }
  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  img {
    border-style: none;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  code,
  kbd,
  pre {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  input {
    font: inherit;
    margin: 0;
    overflow: visible;
  }
  button:-moz-focusring,
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  [type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }

  input {
    font: 13px/1.4 Helvetica, arial, nimbussansl, liberationsans, freesans, clean, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }
  a {
    color: #4078c0;
    text-decoration: none;
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
  hr {
    height: 0;
    margin: 10px 0;
    overflow: hidden;
    background: transparent;
    border: 0;
    border-bottom: 1px solid $border-color;
    &::before {
      display: table;
      content: '';
    }
    &::after {
      display: table;
      clear: both;
      content: '';
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5;
  }
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 19px;
  }
  h3 {
    font-size: 15px;
  }
  h4 {
    font-size: 13px;
  }
  h5 {
    font-size: 12px;
  }
  h6 {
    font-size: 11px;
  }
  p {
    margin-top: 0;
    margin-bottom: 10px;
  }
  blockquote {
    margin: 0;
  }
  ul {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  ol {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    ol {
      list-style-type: lower-roman;
    }
  }
  ul {
    ol {
      list-style-type: lower-roman;
    }
    ul ol,
    ol ol {
      list-style-type: lower-alpha;
    }
  }
  ol {
    ul ol,
    ol ol {
      list-style-type: lower-alpha;
    }
  }
  dd {
    margin-left: 0;
  }
  code {
    font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    font-size: 12px;
  }
  pre {
    margin-top: 0;
    margin-bottom: 0;
    font: 12px Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  }
  .pl-0 {
    padding-left: 0 !important;
  }
  .pl-1 {
    padding-left: 3px !important;
  }
  .pl-2 {
    padding-left: 6px !important;
  }
  .pl-3 {
    padding-left: 12px !important;
  }
  .pl-4 {
    padding-left: 24px !important;
  }
  .pl-5 {
    padding-left: 36px !important;
  }
  .pl-6 {
    padding-left: 48px !important;
  }
  .form-select::-ms-expand {
    opacity: 0;
  }
  &:before {
    display: table;
    content: '';
  }
  &:after {
    display: table;
    clear: both;
    content: '';
  }
  > * {
    &:first-child {
      margin-top: 0 !important;
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
  a:not([href]) {
    color: inherit;
    text-decoration: none;
  }
  .anchor {
    display: inline-block;
    padding-right: 2px;
    margin-left: -18px;
    &:focus {
      outline: none;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1em;
    margin-bottom: 16px;
    font-weight: bold;
    line-height: 1.4;
  }
  h1 .octicon-link,
  h2 .octicon-link,
  h3 .octicon-link,
  h4 .octicon-link,
  h5 .octicon-link,
  h6 .octicon-link {
    color: #000;
    vertical-align: middle;
    visibility: hidden;
  }
  h1:hover .anchor,
  h2:hover .anchor,
  h3:hover .anchor,
  h4:hover .anchor,
  h5:hover .anchor,
  h6:hover .anchor {
    text-decoration: none;
  }
  h1:hover .anchor .octicon-link,
  h2:hover .anchor .octicon-link,
  h3:hover .anchor .octicon-link,
  h4:hover .anchor .octicon-link,
  h5:hover .anchor .octicon-link,
  h6:hover .anchor .octicon-link {
    visibility: visible;
  }
  h1 {
    padding-bottom: 0.3em;
    font-size: 2.25em;
    line-height: 1.2;
    border-bottom: 1px solid $border-color;
    .anchor {
      line-height: 1;
    }
  }
  h2 {
    padding-bottom: 0.3em;
    font-size: 1.75em;
    line-height: 1.225;
    border-bottom: 1px solid $border-color;
    .anchor {
      line-height: 1;
    }
  }
  h3 {
    font-size: 1.5em;
    line-height: 1.43;
    .anchor {
      line-height: 1.2;
    }
  }
  h4 {
    font-size: 1.25em;
    .anchor {
      line-height: 1.2;
    }
  }
  h5 {
    font-size: 1em;
    .anchor {
      line-height: 1.1;
    }
  }
  h6 {
    font-size: 1em;
    color: #777;
    .anchor {
      line-height: 1.1;
    }
  }
  p,
  blockquote,
  ul,
  ol,
  dl,
  table,
  pre {
    margin-top: 0;
    margin-bottom: 16px;
  }
  hr {
    height: 4px;
    padding: 0;
    margin: 16px 0;
    background-color: #e7e7e7;
    border: 0 none;
  }
  ul,
  ol {
    padding-left: 2em;
  }
  ul {
    ul,
    ol {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  ol {
    ol,
    ul {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  li > p {
    margin-top: 16px;
  }
  dl {
    padding: 0;
    dt {
      padding: 0;
      margin-top: 16px;
      font-size: 1em;
      font-style: italic;
      font-weight: bold;
    }
    dd {
      padding: 0 16px;
      margin-bottom: 16px;
    }
  }
  blockquote {
    padding: 0 15px;
    color: #777;
    border-left: 4px solid $border-color;
    > {
      :first-child {
        margin-top: 0;
      }
      :last-child {
        margin-bottom: 0;
      }
    }
  }
  table {
    display: table;
    width: 100%;
    overflow: auto;
    th {
      font-weight: bold;
      padding: 2px 10px;
      border: 1px solid $table-border-color;
    }
    td {
      padding: 2px 10px;
      border: 1px solid $table-border-color;
    }
    tr {
      background-color: $table-bg;

      &:nth-child(2n) {
        background-color: darken($table-bg, 2%);
      }
    }
  }
  img {
    max-width: 100%;
    box-sizing: content-box;
    background-color: $panel-bg;
  }
  code {
    padding: 0;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    margin: 0;
    font-size: 85%;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 3px;
    &:before,
    &:after {
      letter-spacing: -0.2em;
      content: '\00a0';
    }
  }
  pre > code {
    padding: 0;
    margin: 0;
    font-size: 100%;
    word-break: normal;
    white-space: pre;
    background: transparent;
    border: 0;
  }
  .highlight {
    margin-bottom: 16px;
    pre {
      padding: 16px;
      overflow: auto;
      font-size: 85%;
      line-height: 1.45;
      background-color: $panel-bg;
      border-radius: 3px;
    }
  }
  pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: $panel-bg;
    border-radius: 3px;
  }
  .highlight pre {
    margin-bottom: 0;
    word-break: normal;
  }
  pre {
    word-wrap: normal;
    code {
      display: inline;
      max-width: initial;
      padding: 0;
      margin: 0;
      overflow: initial;
      line-height: inherit;
      word-wrap: normal;
      background-color: transparent;
      border: 0;
      &:before,
      &:after {
        content: normal;
      }
    }
  }
  kbd {
    display: inline-block;
    padding: 3px 5px;
    font-size: 11px;
    line-height: 10px;
    color: #555;
    vertical-align: middle;
    background-color: #fcfcfc;
    border: solid 1px $border-color;
    border-bottom-color: #bbb;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 #bbb;
  }
  .pl-c {
    color: #969896;
  }
  .pl-c1,
  .pl-s .pl-v {
    color: #0086b3;
  }
  .pl-e,
  .pl-en {
    color: #795da3;
  }
  .pl-s .pl-s1,
  .pl-smi {
    color: #333;
  }
  .pl-ent {
    color: #63a35c;
  }
  .pl-k {
    color: #a71d5d;
  }
  .pl-pds {
    color: #183691;
  }
  .pl-s {
    color: #183691;
    .pl-pse .pl-s1 {
      color: #183691;
    }
  }
  .pl-sr {
    color: #183691;
    .pl-cce,
    .pl-sra,
    .pl-sre {
      color: #183691;
    }
  }
  .pl-v {
    color: #ed6a43;
  }
  .pl-id {
    color: #b52a1d;
  }
  .pl-ii {
    background-color: #b52a1d;
    color: #f8f8f8;
  }
  .pl-sr .pl-cce {
    color: #63a35c;
    font-weight: bold;
  }
  .pl-ml {
    color: #693a17;
  }
  .pl-mh {
    color: #1d3e81;
    font-weight: bold;
    .pl-en {
      color: #1d3e81;
      font-weight: bold;
    }
  }
  .pl-ms {
    color: #1d3e81;
    font-weight: bold;
  }
  .pl-mq {
    color: #008080;
  }
  .pl-mi {
    color: #333;
    font-style: italic;
  }
  .pl-mb {
    color: #333;
    font-weight: bold;
  }
  .pl-md {
    background-color: #ffecec;
    color: #bd2c00;
  }
  .pl-mi1 {
    background-color: #eaffea;
    color: #55a532;
  }
  .pl-mdr {
    color: #795da3;
    font-weight: bold;
  }
  .pl-mo {
    color: #1d3e81;
  }
  kbd {
    display: inline-block;
    padding: 3px 5px;
    font: 11px Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    line-height: 10px;
    color: #555;
    vertical-align: middle;
    background-color: #fcfcfc;
    border: solid 1px $border-color;
    border-bottom-color: $border-color;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 $border-color;
  }
  .full-commit .btn-outline:not(:disabled):hover {
    color: #4078c0;
    border: 1px solid #4078c0;
  }
  :checked + .radio-label {
    position: relative;
    z-index: 1;
    border-color: #4078c0;
  }
  .octicon {
    display: inline-block;
    vertical-align: text-top;
    fill: currentColor;
  }
  .task-list-item {
    list-style-type: none;
    + .task-list-item {
      margin-top: 3px;
    }
    input {
      margin: 0 0.2em 0.25em -1.6em;
      vertical-align: middle;
    }
  }
  hr {
    border-bottom-color: $border-color;
  }
}

.editor-preview,
.editor-preview-side {
  table {
    width: 100%;
    td,
    th {
      border-color: $table-border-color;
    }
  }
}

.markdown-popover {
  max-height: 400px;
  overflow-y: auto;
  max-width: 400px !important;

  .popover-content {
    zoom: 0.9;
    -moz-transform: scale(0.9);
    -moz-transform-origin: 0 0;
  }
}
