.btn:focus,
.btn:active,
.btn:active:focus {
  outline: none;
}

.btn-login {
  @extend .btn;

  color: white;
  background: $green-base;
  border-color: darken($green-base, 5%);

  &:hover,
  &:focus,
  &:active {
    color: white;
    background: darken($green-base, 7%);
    border-color: darken($green-base, 12%);
  }

  &.btn-sso {
    text-align: left;

    .btn-icon {
      margin-right: 9px;
      padding-right: 10px;
      display: inline-block;
      border-right: 1px solid white;
    }
  }

  &.btn-add {
    color: white;
    background-color: #0772c4;

    &:hover,
    &:focus,
    &:active {
      color: white;
      background-color: darken(#0772c4, 5%);
      border-color: darken(#0772c4, 10%);
    }
  }
}

.btn-toolbar {
  &.btn-toolbar-white {
    background: #fff;
    border: 1px solid $border-color;
    padding: 5px;

    .input-group:first-child {
      margin-left: 0;
    }
  }
}

.btn {
  &:focus,
  &.active:focus {
    outline: none;
  }
}
